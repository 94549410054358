"use client";

import Image from "next/image";
import Link from "next/link";
import React from "react";

import imgBgGeckosInSpaceship from "@/assets/bg-geckos-in-spaceship.png";
import imgFlyingObject from "@/assets/flying-object.png";
import imgLogoTextGGSG from "@/assets/logo-text-ggsg.png";
import imgSatellite from "@/assets/satellite.png";
import { Footer } from "@/components/common/misc/footer";

export default function Page() {
  return (
    <>
      <div className="flex min-h-screen flex-col">
        {/* Content */}
        <div className="flex-auto pt-[160px] md:pt-[320px]">
          <div className="relative z-10 mb-20 flex flex-col items-center justify-center text-center">
            {/* Logo Text */}
            <div className="mb-4 h-[56px] w-[150px] md:mb-5 md:h-[106px] md:w-[284px]">
              <Image
                alt="GGSG"
                src={imgLogoTextGGSG}
                quality={75}
                className="h-auto max-w-full"
                width={821}
                height={309}
                sizes="(min-width: 768px) 284px, 150px"
                priority
              />
            </div>
            <div className="mx-4 text-white md:mx-0">
              <h1 className="indent-[0.545rem] font-zombie text-2xl tracking-[0.545rem] md:text-4xl">
                Galactic Gecko
                <i className="block xl:hidden" /> Space Garage
              </h1>
            </div>
            {/* Members Link */}
            <p className="mt-10 font-jost">Your Journey Starts Here</p>
            <Link
              href="/member/dashboard"
              style={{
                border: "none",
                background: "transparent",
                marginTop: "28px",
              }}
            >
              <div className="z-10 flex !h-auto max-w-[366px] animate-glow-crystal !rounded-none !border-2 !border-crystal !bg-black !px-6 !pb-3 !pt-1 text-center !indent-[0.25rem] font-zombie !text-2xl leading-none !tracking-[0.25rem] text-white hover:animate-pulse [&>.wallet-adapter-button-start-icon]:hidden">
                Open The Garage
              </div>
            </Link>
          </div>
        </div>

        {/* Spaceship */}
        <div className="relative mt-[-25%] flex-none 5xl-above:!hidden">
          <div className="pointer-events-none mx-auto w-full select-none">
            <Image
              alt="Spaceship Cockpit"
              src={imgBgGeckosInSpaceship}
              quality={75}
              sizes="(max-width: 600px) 412px, 1350px"
              className="h-auto w-full max-w-full"
              priority
            />
          </div>
          <div className="pointer-events-none absolute left-[6.5%] top-[37%] w-[7.5%] max-w-[138px] select-none">
            <Image
              alt="Flying Object"
              src={imgFlyingObject}
              quality={75}
              className="h-auto max-w-full"
              width={138}
              height={70}
              sizes="(min-width: 1840px) 138px, 7.5vw"
            />
          </div>
          <div className="pointer-events-none absolute right-[6.5%] top-[32.5%] w-[6%] max-w-[150px] select-none">
            <Image
              alt="imgSatellite"
              src={imgSatellite}
              quality={75}
              className="h-auto max-w-full"
              width={788}
              height={949}
              sizes="(min-width: 2500px) 150px, 6vw"
            />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
